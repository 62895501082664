import { createTheme } from '@mui/material';

export const Colors = {
  // primary: '#1976d2', // LIVE site color
  // secondary: '#8e24aa', // LIVE site color
  primary: '#0097a7', // TEST site color
  secondary: '#5e35b1', // TEST site color
  error: '#dc3545',
  warning: '#c29200',
  info: '#0277bd',
  success: '#198754',
  black: '#000000',
  white: '#ffffff',
  grey: '#757575',
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      s_sm: 450,
      sm: 600,
      s_md: 769,
      md: 900,
      s_lg: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.secondary,
      contrastText: Colors.white,
    },
    error: {
      main: Colors.error,
      contrastText: Colors.white,
    },
    warning: {
      main: Colors.warning,
      contrastText: Colors.white,
    },
    info: {
      main: Colors.info,
      contrastText: Colors.white,
    },
    success: {
      main: Colors.success,
      contrastText: Colors.white,
    },
    white: {
      main: Colors.white,
      contrastText: Colors.black,
    },
    black: {
      main: Colors.black,
      contrastText: Colors.white,
    },
    grey: {
      main: Colors.grey,
      contrastText: Colors.white,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
      defaultProps: {
        // disableRipple: true,
        disableElevation: true,
      },
    },
  },
});

export default theme;

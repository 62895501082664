import React from 'react';
import useLayout from '../hooks/useLayout';
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { CustomListItem } from '../assets/style/Sidebar';
// import logo from '../assets/images/logo.svg'; // LIVE
// import collapseLogo from '../assets/images/mobile_logo.svg'; // LIVE
import logo from '../assets/images/placeholder-logo.png'; // TEST
import collapseLogo from '../assets/images/placeholder-logo-icon.png'; // TEST

export default function Sidebar({ options }) {
  const matches = useMediaQuery('(max-width:900px)');
  const { setBackDrop, setLeft } = useLayout();
  const { collapsemenu, setCollapseMenu } = useLayout();

  const handleCollapse = () => {
    setCollapseMenu(!collapsemenu);
  };

  return (
    <>
      {!matches && (
        <Box sx={{ position: 'relative' }}>
          <Tooltip title={collapsemenu ? 'Expand Menu' : 'Collapse Menu'}>
            <IconButton
              disableRipple
              onClick={handleCollapse}
              color="white"
              sx={{
                padding: 0,
                position: 'absolute',
                right: '-12px',
                top: '50%',
                transform: `translateY(-50%) rotate(${
                  collapsemenu ? '180' : '0'
                }deg)`,
                zIndex: 999,
              }}>
              <MenuOpenIcon />
            </IconButton>
          </Tooltip>

          <Box
            sx={{
              height: '64px',
              maxWidth: '176px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: (theme) => theme.palette.primary.main,
            }}>
            <Link to="/" state={{ title: 'Dashboard' }}>
              <img
                src={collapsemenu ? collapseLogo : logo}
                alt="logo"
                style={{
                  minWidth: '100%',
                  width: '100%',
                  objectFit: 'contain',
                  padding: '12px 16px 12px 6px',
                }}
              />
            </Link>
          </Box>
        </Box>
      )}

      <List
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          height: 'calc(100vh - 65px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: 7,
          },
        }}>
        {options?.map((info, index) => (
          <CustomListItem
            key={index}
            disablePadding
            onClick={() => {
              setBackDrop(false);
              setLeft(-1000);
            }}
            component={NavLink}
            to={info?.url}
            state={{
              title: info?.title,
            }}>
            <ListItemButton>
              <Tooltip
                title={collapsemenu ? info?.title : ''}
                placement="right">
                <ListItemIcon>{info?.icon}</ListItemIcon>
              </Tooltip>

              <ListItemText
                primary={info?.title}
                sx={{
                  display: collapsemenu ? 'none' : 'initial',
                  whiteSpace: 'nowrap',
                }}
              />
            </ListItemButton>
          </CustomListItem>
        ))}
      </List>
    </>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  IconButton,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import Modal from '../../component/Modals';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import getRandomColorName from '../../utils/getRandomColorName';
import useHeader from '../../hooks/useHeader';

export default function DealerInformation() {
  const { role, userId, Admins } = useAuth();
  const { setHeader } = useHeader();

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [verifyShow, setVerifyShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  const [status, setStatus] = useState('pending');
  const [phoneCount, setPhoneCount] = useState({});

  const [verifyData, setVerifyData] = useState({});
  const [deleteId, setDeleteId] = useState('');

  const handleEditShow = (data) => {
    navigate('/dealerform', { state: data });
  };

  const handleVerifyShow = (data) => {
    setStatus(data.status);
    setVerifyData(data);
    setVerifyShow(true);
  };

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleVerify = () => {
    axiosPrivate
      .patch(`/api/v1/dealer/${verifyData.id}`, { status })
      .then((res) => {
        if (res.status === 200) {
          toast.success('Updated successfully');
          refetch();
          setVerifyShow(false);
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/dealer/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
          setDeleteShow(false);
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  const handleTaDa = (data) => {
    navigate('/tadaform', {
      state: {
        dealerSearchRef: data?.id,
        travelDate: data?.visitDate,
        reasonForTravel: 'Dealer search visit',
        region: data?.region,
        headquarter: data?.hq,
        point: data?.pointTown,
        latitude: data?.latitude,
        longitude: data?.longitude,
        from: 'FORM',
      },
    });
  };

  // get Dealer Information
  const { data, isLoading, isError, error, refetch } = useQuery(
    'dealerinfo',
    () => {
      if (!['Super Admin', ...Admins, 'IMS'].includes(role)) {
        return axiosPrivate.get(
          `/api/v1/dealer?populate=yes&limit=none&sort=-createdAt&hq=${localStorage.getItem(
            'hqId',
          )}`,
        );
      }
      return axiosPrivate.get(
        '/api/v1/dealer?populate=yes&limit=none&sort=-createdAt',
      );
    },
  );
  const d = data?.data?.data;

  useEffect(() => {
    setHeader({ title: 'Dealer Search Visit Info' });
  }, []);

  // find duplicate phone count
  useEffect(() => {
    if (Array.isArray(d)) {
      const pCount = {};
      d?.forEach((d) => {
        if (pCount[d.primaryNo]?.count) {
          pCount[d.primaryNo].count += 1;
        } else {
          pCount[d.primaryNo] = {
            count: 1,
            color: getRandomColorName(),
          };
        }
      });
      setPhoneCount(pCount);
    }
  }, [d]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        Header: 'Time of Visit',
        accessor: 'visitDate',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Visited By',
        accessor: 'recordedBy.nameCode',
      },
      {
        Header: 'Emp. Role',
        accessor: 'recordedBy.role.employeeRole',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Dealer Name',
        accessor: 'dealerName',
      },
      {
        Header: 'Contact No.',
        accessor: 'primaryNo',
        Cell: ({ value }) => {
          if (phoneCount[value]?.count > 1) {
            return (
              <span style={{ color: phoneCount[value].color }}>
                <span
                  style={{
                    borderRadius: 8,
                    color: 'white',
                    backgroundColor: phoneCount[value].color,
                  }}>
                  &nbsp;{phoneCount[value].count}&nbsp;
                </span>
                {` ${value}`}
              </span>
            );
          }
          return value;
        },
      },
      // {
      //   Header: 'Contact No 2',
      //   accessor: 'secondaryNo',
      // },
      // {
      //   Header: 'Dealer Type',
      //   accessor: 'dealerType',
      // },
      {
        Header: 'Region',
        accessor: 'region.name',
        Cell: ({ value }) => <Box sx={{ whiteSpace: 'nowrap' }}>{value}</Box>,
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'HQ',
        accessor: 'hq.name',
        Cell: ({ value }) => <Box sx={{ whiteSpace: 'nowrap' }}>{value}</Box>,
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Point/Town',
        accessor: 'pointTown.name',
        Cell: ({ value }) => <Box sx={{ whiteSpace: 'nowrap' }}>{value}</Box>,
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      // {
      //   Header: 'DealerShip Company Names',
      //   accessor: 'companyName',
      // },
      // {
      //   Header: 'Approx Sales Volume In Taka (In Tp Value)',
      //   accessor: 'approxSale',
      // },

      // {
      //   Header: 'Product Category',
      //   accessor: 'productCategory',
      //   Cell: ({ value }) => {
      //     if (value.length > 1) {
      //       const firstTwoValues = value.slice(0, 1);

      //       return firstTwoValues.map((item, index) => {
      //         return (
      //           <p>
      //             {index + 1}. {item} <br />
      //             {'...'}
      //           </p>
      //         );
      //       });
      //     } else {
      //       return value;
      //     }
      //   },
      // },

      // {
      //   Header: 'Transaction Category',
      //   accessor: 'transactionCategory',
      // },

      // {
      //   Header: 'No Of Running So / Sr',
      //   accessor: 'runningSO_SR',
      // },
      // {
      //   Header: 'No Of Vans',
      //   accessor: 'noOfVans',
      // },

      {
        Header: 'Geo Location',
        Cell: ({ row }) => {
          if (!row?.original?.latitude || !row?.original?.longitude)
            return <Typography color="error">NO DATA</Typography>;
          return (
            <Link
              href={encodeURI(
                `https://www.google.com/maps/search/?api=1&query=${row?.original?.latitude},${row?.original?.longitude}`,
              )}
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
              title="Open in Google Map">
              <Icon sx={{ fontSize: 14 }}>location_on</Icon>
              {` ${row?.original?.latitude}, ${row?.original?.longitude}`}
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        id: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          if (value === 'verified')
            return (
              <Tooltip title="Verified">
                <CheckCircleIcon color="success" />
              </Tooltip>
            );
          if (value === 'failed')
            return (
              <Tooltip title="Failed">
                <CancelIcon color="error" />
              </Tooltip>
            );
          return (
            <Tooltip title="Pending">
              <HelpOutlineIcon color="primary" />
            </Tooltip>
          );
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Entry Time',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Option',
        id: 'option',
        Cell: ({ row }) =>
          row?.original?.recordedBy === userId ? (
            <Button
              disabled={row?.original?.clamiedTaDa}
              variant="outlined"
              size="small"
              onClick={() => handleTaDa(row?.original)}
              sx={{ whiteSpace: 'nowrap' }}>
              Claim TA DA
            </Button>
          ) : null,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" spacing={3}>
              <Tooltip title="Update verification status">
                <IconButton
                  onClick={() => handleVerifyShow(row?.original)}
                  className="Table_icon_btn">
                  <BeenhereIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Update Dealer information">
                <IconButton
                  onClick={() => handleEditShow(row?.original)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
              </Tooltip>

              {role === 'Super Admin' && (
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDeleteShow(row?.original?.id)}
                    className="Table_icon_btn delete">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
    ],
    [phoneCount, role],
  );

  if (isLoading) {
    return <TableSkeleton />;
  }

  if (isError) {
    expired(error);
  }

  return (
    <Box>
      <Table
        columns={COLUMNS}
        data={data?.data?.data}
        filename="DealerInfo"
        addButtonTooltip="Add Dealer"
        onAddButtonClick={() => navigate('/dealerform')}
        expandRow={ExpandRowInfo}
        fullHeight
      />

      <Modal
        title="Update verification status"
        show={verifyShow}
        onSave={handleVerify}
        onHide={() => setVerifyShow(false)}
        width="lg"
        form>
        <Stack direction="column" gap={3}>
          <Typography>
            Set verification status for <strong>{verifyData.outletName}</strong>
          </Typography>

          <FormControl>
            <FormLabel id="outlet-status-label">Verification Status</FormLabel>
            <RadioGroup
              aria-labelledby="outlet-status-label"
              name="outlet-status"
              value={status}
              defaultValue="pending"
              onChange={(e) => setStatus(e.target.value)}>
              <FormControlLabel
                value="pending"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    Pending&nbsp;
                    <HelpOutlineIcon color="primary" fontSize="small" />
                  </Box>
                }
              />
              <FormControlLabel
                value="verified"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    Verified&nbsp;
                    <CheckCircleIcon color="success" fontSize="small" />
                  </Box>
                }
              />
              <FormControlLabel
                value="failed"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    Failed&nbsp;
                    <CancelIcon color="error" fontSize="small" />
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
          <Button variant="contained" onClick={handleVerify}>
            Update
          </Button>
        </Stack>
      </Modal>

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </Box>
  );
}

function ExpandRowInfo({ data }) {
  const { role, Admins } = useAuth();
  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        p={2}
        justifyContent={'center'}
        gap={2}>
        <Box flexGrow={1}>
          <Box component={Paper}>
            <caption style={caption}>Dealer Information</caption>
            <table
              cellSpacing="0"
              cellPadding="0"
              border="0"
              style={{ width: '100%' }}>
              {['Super Admin', ...Admins, 'IMS'].includes(role) ? (
                <tr>
                  <td>
                    <b>Status</b>
                  </td>
                  <td>:</td>
                  <td>
                    <Typography sx={{ textTransform: 'capitalize' }}>
                      {data?.status}
                    </Typography>
                  </td>
                </tr>
              ) : null}
              <tr>
                <td>
                  <b>Dealer Name</b>
                </td>
                <td>:</td>
                <td>{data?.dealerName}</td>
              </tr>

              <tr>
                <td>
                  <b>Dealer Contact 1</b>
                </td>
                <td>:</td>
                <td>{data?.primaryNo}</td>
              </tr>
              <tr>
                <td>
                  <b>Dealer Contact 2</b>
                </td>
                <td>:</td>
                <td>{data?.secondaryNo}</td>
              </tr>
              <tr>
                <td>
                  <b>Dealer type</b>
                </td>
                <td>:</td>
                <td>{data?.dealerType}</td>
              </tr>
              <tr>
                <td>
                  <b>Region</b>
                </td>
                <td>:</td>
                <td>
                  {data?.region?.name} ({data?.region?.code})
                </td>
              </tr>
              <tr>
                <td>
                  <b>Hq</b>
                </td>
                <td>:</td>
                <td>
                  {data?.hq?.name} ({data?.hq?.code})
                </td>
              </tr>
              <tr>
                <td>
                  <b>Point/Town</b>
                </td>
                <td>:</td>
                <td>
                  {data?.pointTown?.name} ({data?.pointTown?.code})
                </td>
              </tr>
              <tr>
                <td>
                  <b>Dealer Company Name</b>
                </td>
                <td>:</td>
                <td>{data?.companyName}</td>
              </tr>
              <tr>
                <td>
                  <b>Approx Sales Volume In Taka (In Tp Value)</b>
                </td>
                <td>:</td>
                <td>{data?.approxSale}</td>
              </tr>
              <tr>
                <td>
                  <b>Product Category</b>
                </td>
                <td>:</td>
                <td>
                  {data?.productCategory.map((item, index) => (
                    <p>
                      {index + 1}. {item}
                    </p>
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Transaction Category</b>
                </td>
                <td>:</td>
                <td>{data?.transactionCategory}</td>
              </tr>
              <tr>
                <td>
                  <b>No Of Running So / Sr</b>
                </td>
                <td>:</td>
                <td>{data?.runningSO_SR}</td>
              </tr>
              <tr>
                <td>
                  <b>No Of Vans</b>
                </td>
                <td>:</td>
                <td>{data?.noOfVans}</td>
              </tr>
              <tr>
                <td>
                  <b>Geo Location</b>
                </td>
                <td>:</td>
                <td>
                  {data?.latitude ? (
                    `${data?.latitude} , ${data?.longitude}`
                  ) : (
                    <Typography color="error">NO DATA</Typography>
                  )}
                </td>
              </tr>
            </table>
          </Box>
          <Box my={2} component={Paper} mt={3}>
            <caption style={caption}>Company Information</caption>
            <table
              cellSpacing="0"
              cellPadding="0"
              border="0"
              style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ fontSize: 14 }}>Company Name</th>
                  <th style={{ fontSize: 14 }}>Product Category</th>
                  <th style={{ fontSize: 14 }}>Approx Sale</th>
                </tr>
              </thead>
              <tbody>
                {data?.companies?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={3}
                      style={{ textAlign: 'center', padding: '30px 0px' }}>
                      No data found
                    </td>
                  </tr>
                ) : (
                  data?.companies.map((company, companyIndex) => (
                    <tr key={companyIndex}>
                      <td style={{ fontSize: 14 }}>{company.companyName}</td>
                      <td style={{ fontSize: 14 }}>{company.productCat}</td>
                      <td style={{ fontSize: 14 }}>{company.amount}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Box>
        </Box>
        <Box flexGrow={1} component={Paper}>
          <caption style={caption}>Recording Person Information</caption>
          <table
            cellSpacing="0"
            cellPadding="0"
            border="0"
            style={{ width: '100%' }}>
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td>:</td>
              <td>{data?.name}</td>
            </tr>
            <tr>
              <td>
                <b>Mobile No</b>
              </td>
              <td>:</td>
              <td>{data?.mobileNo}</td>
            </tr>
          </table>
        </Box>
      </Stack>
    </>
  );
}

const caption = {
  textAlign: 'center',
  display: 'block',
  color: '#fff',
  background: '#1976d2',
  padding: '5px',
};

// prettier-ignore
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import SkuAnalysis from '../component/DashBoardReportCharts/SkuAnalysis';
import Layout from '../component/Layout';
import useAuth from '../hooks/useAuth';
import AuthSetup from '../pages/AuthSetup/AuthSetup';
import AuthDevice from '../pages/AuthSetup/Devices/AuthDevice';
import AuthUsers from '../pages/AuthSetup/Users/AuthUsers';
import Collections from '../pages/Collections/Collections';
import ModifiedCollections from '../pages/Collections/ModifiedCollections';
import AllCustomer from '../pages/Customer/AllCustomer/AllCustomer';
import Ledger from '../pages/Customer/AllCustomer/Ledger/Ledger';
import LedgerReport from '../pages/Customer/AllCustomer/LedgerReport/LedgerReport';
import ModifiedCustomer from '../pages/Customer/AllCustomer/ModifiedCustomer';
import CustomerStock from '../pages/Customer/AllCustomer/Stock/CustomerStock';
import Transaction from '../pages/Customer/AllCustomer/Transactions/Transactions';
import CustomerClass from '../pages/Customer/CustomerClass/CustomerClass';
import ModifiedCustomerClass from '../pages/Customer/CustomerClass/ModifiedCustomerClass';
import CustomerType from '../pages/Customer/CustomerType/CustomerType';
import ModifiedCustomerType from '../pages/Customer/CustomerType/ModifiedCustomerType';
import Damages from '../pages/Damages/Damages';
import AllEmployees from '../pages/Employees/AllEmployees/AllEmployees';
import ModifiedEmployees from '../pages/Employees/AllEmployees/ModifiedEmployees';
import EmployeeRole from '../pages/Employees/EmployeeRole/EmployeeRole';
import ModifiedEmployeeRole from '../pages/Employees/EmployeeRole/ModifiedEmployeeRole';
import FactoryStock from '../pages/Factory/FactoryStock';
import Home from '../pages/Home';
import LiftingPlan from '../pages/LiftingPlan';
import LiftingPlanDetails from '../pages/LiftingPlan/LiftingPlanDetails';
import LiftingPlanSummary from '../pages/LiftingPlan/LiftingPlanSummary';
import ModifyLiftingPlan from '../pages/LiftingPlan/ModifyLiftingPlan';
import MarketPrice from '../pages/MarketPrice/MarketPrice';
import ModifyMarketPrice from '../pages/MarketPrice/ModifyMarketPrice';
import Missing from '../pages/Missing';
import AddPayments from '../pages/Payments/AddPayments';
import EditPayment from '../pages/Payments/EditPayment';
import Payments from '../pages/Payments/Payments';
import Payroll from '../pages/Payroll';
import ModifyMultipleParollTicket from '../pages/Payroll/ModifyMultipleParollTicket';
import ModifyPayrollTicket from '../pages/Payroll/ModifyPayrollTicket';
import PayrollLedger from '../pages/Payroll/PayrollLedger';
import PayrollPayslip from '../pages/Payroll/PayrollPayslip';
import PayrollReport from '../pages/Payroll/PayrollReport';
import PayrollTickets from '../pages/Payroll/PayrollTickets';
import PhysicalStocks from '../pages/PhysicalStocks/PhysicalStocks';
import ModifyProduct from '../pages/Product/AllProducts/ModifyProduct';
import ViewProduct from '../pages/Product/AllProducts/ViewProduct';
import ModifyProductCompany from '../pages/Product/ProductCompany/ModifyProductCompany';
import ViewProdcutCompany from '../pages/Product/ProductCompany/ViewProdcutCompany';
import ModifiedProductCat from '../pages/Product/ProductsCat/ModifiedProductCat';
import ViewProdcutCat from '../pages/Product/ProductsCat/ViewProdcutCat';
import Profile from '../pages/Profile/Profile';
import CompanyCategorySales from '../pages/Report/CompanyCategorySales';
import CustCollection from '../pages/Report/CustCollection';
import CustStock from '../pages/Report/CustStock';
import CustomerActivity from '../pages/Report/CustomerActivity';
import CustomerAuditedIMS from '../pages/Report/CustomerAuditedIMS';
import DoSection from '../pages/Report/DoSection';
import EmployeeAttendance from '../pages/Report/EmployeeAttendance';
import EmployeeWiseCust from '../pages/Report/EmployeeWiseCust';
import HqWiseSku from '../pages/Report/HqWiseSku';
import NationalAuditedIMS from '../pages/Report/NationalAuditedIMS';
import NationalIMS from '../pages/Report/NationalIMS';
import Report from '../pages/Report/Report';
import SDCommission from '../pages/Report/SDCommission';
import SKUContribution from '../pages/Report/SKUContribution';
import Settings from '../pages/Settings/Settings';
import SrReport from '../pages/SrReport';
import ModifySrReport from '../pages/SrReport/ModifySReportDetails';
import SrReportDetails from '../pages/SrReport/SrReportDetails';
import SrReportSummary from '../pages/SrReport/SrReportSummary';
import ModifyStock from '../pages/Stock/ModifyStock';
import Stock from '../pages/Stock/Stock';
import ModifyTarget from '../pages/Target/ModifyTarget';
import Targets from '../pages/Target/Targets';
import AllTeritories from '../pages/Territories/AllTeritories/AllTeritories';
import ModifiedTerritories from '../pages/Territories/AllTeritories/ModifiedTerritories';
import TerritoryType from '../pages/Territories/TerritoryType/TerritoryType';
import TestTheme from '../pages/TestTheme';
import ModifyTransactionType from '../pages/Transactions/TransactionTypes/ModifyTransactionType';
import ViewTransactionsType from '../pages/Transactions/TransactionTypes/ViewTransactionsType';
import AddDepotOrder from '../pages/Transactions/Transactions/AddDepotOrder';
import ConfirmDelivery from '../pages/Transactions/Transactions/ConfirmDelivery';
import ConfirmPayment from '../pages/Transactions/Transactions/ConfirmPayment';
import LinkCollections from '../pages/Transactions/Transactions/LinkCollections';
import ModifyTransaction from '../pages/Transactions/Transactions/ModifyTransaction';
import ViewTransactions from '../pages/Transactions/Transactions/ViewTransactions';
import ViewTransfers from '../pages/Transfers/ViewTransfers';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Login from '../pages/auth/Login';
import SignUp from '../pages/auth/SignUp';
import DealerForm from '../pages/form/DealerForm';
import DealerInformation from '../pages/form/DealerInformation';
import ExitingDealerForm from '../pages/form/ExitingDealerForm';
import ExitingDealerInformation from '../pages/form/ExitingDealerInformation';
import OutletForm from '../pages/form/OutletForm';
import OutletInfo from '../pages/form/OutletInfo';
import TaDaForm from '../pages/form/TaDa/TaDaForm';
import TaDaInformation from '../pages/form/TaDa/TaDaInfo';
import TaDaDetails from '../pages/form/TaDa/TaDaInfo/TaDaDetails';
import TaDaSummary from '../pages/form/TaDa/TaDaInfo/TaDaSummary';
import PersistLogin from '../utils/PersistLogin';
import RequireAuth from '../utils/RequireAuth';
import UnAuthorized from '../utils/UnAuthorized';
import Welcome from '../utils/Welcome';
// import Import from '../pages/Import';

function Router() {
  const { role, Admins, SalesManagers, SalesOfficers } = useAuth();
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // refetchOnmount: false,
        // refetchOnReconnect: false,
        retry: false,
        // staleTime: twentyFourHoursInMs,
      },
    },
  });

  return (
    <QueryClientProvider client={client}>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route element={<PersistLogin />}>
          {/* can view: Super Admin */}
          <Route element={<RequireAuth allowedRoles={['Super Admin']} />}>
            <Route path="auth" element={<AuthSetup />}>
              <Route index element={<Navigate to="users" />} />
              <Route path="users" element={<AuthUsers />} />
              <Route path="devices" element={<AuthDevice />} />
            </Route>

            <Route
              path="settings"
              element={
                <Layout>
                  <Settings />
                </Layout>
              }>
              <Route index element={<Navigate to="territoy-type" />} />
              <Route path="territoy-type" element={<TerritoryType />} />
              <Route path="employee-role" element={<EmployeeRole />}>
                <Route path="add" element={<ModifiedEmployeeRole />} />
                <Route
                  path="edit/:employeeRoleID"
                  element={<ModifiedEmployeeRole />}
                />
              </Route>
              <Route path="customer-type" element={<CustomerType />}>
                <Route path="add" element={<ModifiedCustomerType />} />
                <Route
                  path="edit/:customerTypeID"
                  element={<ModifiedCustomerType />}
                />
              </Route>
              <Route path="customer-class" element={<CustomerClass />}>
                <Route path="add" element={<ModifiedCustomerClass />} />
                <Route
                  path="edit/:customerClassID"
                  element={<ModifiedCustomerClass />}
                />
              </Route>
              <Route path="product-category" element={<ViewProdcutCat />}>
                <Route path="add" element={<ModifiedProductCat />} />
                <Route
                  path="edit/:productCatID"
                  element={<ModifiedProductCat />}
                />
              </Route>
              <Route path="product-company" element={<ViewProdcutCompany />}>
                <Route path="add" element={<ModifyProductCompany />} />
                <Route
                  path="edit/:productComID"
                  element={<ModifyProductCompany />}
                />
              </Route>
              <Route path="transaction-type" element={<ViewTransactionsType />}>
                <Route path="add" element={<ModifyTransactionType />} />
                <Route
                  path="edit/:transactionTypeId"
                  element={<ModifyTransactionType />}
                />
              </Route>
            </Route>
          </Route>

          {/* can view: Super Admin , Admin */}
          <Route
            element={<RequireAuth allowedRoles={['Super Admin', ...Admins]} />}>
            <Route path="charts">
              <Route
                path="sku-anylysis"
                element={
                  <Layout>
                    <SkuAnalysis />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="territories"
              element={
                <Layout>
                  <AllTeritories />
                </Layout>
              }>
              <Route path="add" element={<ModifiedTerritories />} />
              <Route
                path="edit/:territoryID"
                element={<ModifiedTerritories />}
              />
            </Route>

            <Route
              path="employees"
              element={
                <Layout>
                  <AllEmployees />
                </Layout>
              }>
              {role !== 'Admin' ? (
                <>
                  <Route path="add" element={<ModifiedEmployees />} />
                  <Route
                    path="edit/:employeeID"
                    element={<ModifiedEmployees />}
                  />
                </>
              ) : (
                <Route element={<Missing />} />
              )}
            </Route>

            <Route
              path="products"
              element={
                <Layout>
                  <ViewProduct />
                </Layout>
              }>
              <Route path="add" element={<ModifyProduct />} />
              <Route path="edit/:productId" element={<ModifyProduct />} />
            </Route>

            <Route path="report" element={<Report />}>
              <Route path="sku-contribution" element={<SKUContribution />} />
              <Route path="cust-activity" element={<CustomerActivity />} />
              <Route path="cust-collection" element={<CustCollection />} />
              <Route path="do-section" element={<DoSection />} />
              <Route path="cust-stock" element={<CustStock />} />
              <Route path="hq-wise-sku" element={<HqWiseSku />} />
              <Route
                path="asm-wise-active-customer"
                element={<EmployeeWiseCust />}
              />
              <Route path="national-ims" element={<NationalIMS />} />
              <Route
                path="national-audited-ims"
                element={<NationalAuditedIMS />}
              />
              <Route
                path="company-category-sales"
                element={<CompanyCategorySales />}
              />
              <Route
                path="employee-attendance"
                element={<EmployeeAttendance />}
              />
            </Route>

            {/* <Route path="import" element={<Import />} /> */}
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  'Super Admin',
                  ...Admins,
                  'Accounts',
                  'DO',
                  ...SalesManagers,
                  ...SalesOfficers,
                  'SD',
                ]}
              />
            }>
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }>
              <Route path="customers" element={<AllCustomer />}>
                <Route path="add" element={<ModifiedCustomer />} />
                <Route path="edit/:customerID" element={<ModifiedCustomer />} />
                {role !== 'Admin' ? (
                  <Route path="stock/:customerID" element={<CustomerStock />} />
                ) : (
                  <Route element={<Missing />} />
                )}

                <Route path="ledger/:customerID" element={<Ledger />} />
                <Route
                  path="report-ledger/:customerID"
                  element={<LedgerReport />}
                />
                <Route
                  path="transaction/:customerID"
                  element={<Transaction />}
                />
              </Route>
            </Route>

            <Route
              path="collections"
              element={
                <Layout>
                  <Collections />
                </Layout>
              }>
              <Route path="add" element={<ModifiedCollections />} />
              <Route
                path="edit/:collectionID"
                element={<ModifiedCollections />}
              />
            </Route>

            <Route
              path="payments"
              element={
                <Layout>
                  <Payments />
                </Layout>
              }>
              <Route path="add" element={<AddPayments />} />
              <Route path="edit/:ledgerID" element={<EditPayment />} />
            </Route>

            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }>
              <Route path="physical-stocks" element={<PhysicalStocks />} />
            </Route>

            <Route
              path="customer-ledger-report"
              element={
                <Layout>
                  <LedgerReport />
                </Layout>
              }
            />

            <Route
              path="sd-commission"
              element={
                <Layout>
                  <SDCommission />
                </Layout>
              }
            />

            <Route
              path="customer-audited-ims"
              element={
                <Layout>
                  <CustomerAuditedIMS />
                </Layout>
              }
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  'Super Admin',
                  ...Admins,
                  'Accounts',
                  'DO',
                  'Factory',
                  ...SalesManagers,
                  ...SalesOfficers,
                  'SD',
                ]}
              />
            }>
            <Route path="profile" element={<Profile />} />

            <Route path="stock" element={<Stock />}>
              <Route path="add" element={<ModifyStock />} />
              <Route path="edit/:stockId" element={<ModifyStock />} />
            </Route>

            <Route
              path="transactions"
              element={
                <Layout>
                  <ViewTransactions />
                </Layout>
              }>
              <Route path="link-collections" element={<LinkCollections />} />
              <Route path="confirm-payment" element={<ConfirmPayment />} />
              <Route path="confirm-delivery" element={<ConfirmDelivery />} />
              <Route path="add" element={<ModifyTransaction />} />
              <Route
                path="edit/:transactionId"
                element={<ModifyTransaction />}
              />
              <Route path="add-depot-order" element={<AddDepotOrder />} />
            </Route>

            <Route
              path="transfers"
              element={
                <Layout>
                  <ViewTransfers />
                </Layout>
              }
            />

            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }>
              <Route path="damages" element={<Damages />} />
            </Route>
          </Route>

          {/* can view: Super Admin , Admin,ASM , RSM, DSM*/}
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  'Super Admin',
                  ...Admins,
                  ...SalesManagers,
                  ...SalesOfficers,
                ]}
              />
            }>
            <Route index path="/" element={<Home />} />
            <Route
              path="/dealerform"
              element={
                <Layout>
                  <DealerForm />
                </Layout>
              }
            />
            <Route
              path="/exdealerform"
              element={
                <Layout>
                  <ExitingDealerForm />
                </Layout>
              }
            />
            <Route
              path="/tadaform"
              element={
                <Layout>
                  <TaDaForm />
                </Layout>
              }
            />

            <Route
              path="market-price"
              element={
                <Layout>
                  <MarketPrice />
                </Layout>
              }>
              <Route path="add" element={<ModifyMarketPrice />} />
              <Route path="edit/:priceId" element={<ModifyMarketPrice />} />
            </Route>

            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }>
              <Route path="target" element={<Targets />}>
                <Route path="add" element={<ModifyTarget />} />
                <Route path="edit/:targetId" element={<ModifyTarget />} />
              </Route>

              <Route element={<SrReport />}>
                <Route path="sr-report-entries" element={<SrReportDetails />}>
                  <Route path="add" element={<ModifySrReport />} />
                  <Route path="edit/:reportId" element={<ModifySrReport />} />
                </Route>
                <Route
                  path="sr-report-attendance"
                  element={<SrReportSummary />}
                />
              </Route>

              <Route element={<LiftingPlan />}>
                <Route
                  path="lifting-plan-details"
                  element={<LiftingPlanDetails />}>
                  <Route path="add" element={<ModifyLiftingPlan />} />
                  <Route
                    path="edit/:commitmentID"
                    element={<ModifyLiftingPlan />}
                  />
                </Route>
                <Route
                  path="lifting-plan-summary"
                  element={<LiftingPlanSummary />}
                />
              </Route>
            </Route>

            <Route
              element={
                <Layout>
                  <Payroll />
                </Layout>
              }>
              <Route path="payroll-ledger" element={<PayrollLedger />} />
              <Route path="payroll-ticket" element={<PayrollTickets />}>
                {['Super Admin', ...Admins].includes(role) ? (
                  <>
                    <Route path="add" element={<ModifyPayrollTicket />} />
                    <Route
                      path="edit/:ticketID"
                      element={<ModifyPayrollTicket />}
                    />
                    <Route
                      path="add-multiple"
                      element={<ModifyMultipleParollTicket />}
                    />
                  </>
                ) : (
                  <Route element={<Missing />} />
                )}
              </Route>
              {['Super Admin', ...Admins].includes(role) ? (
                <>
                  <Route path="payroll-report" element={<PayrollReport />} />
                  <Route path="payroll-payslip" element={<PayrollPayslip />} />
                </>
              ) : (
                <Route element={<Missing />} />
              )}
            </Route>
          </Route>

          {/* can view: Super Admin , Admin, IMS */}
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  'Super Admin',
                  ...Admins,
                  'IMS',
                  ...SalesManagers,
                  ...SalesOfficers,
                ]}
              />
            }>
            <Route path="profile" element={<Profile />} />
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }>
              <Route path="outletinfo" element={<OutletInfo />} />
              <Route path="dealerinfo" element={<DealerInformation />} />
              <Route
                path="exdealerinfo"
                element={<ExitingDealerInformation />}
              />
              <Route element={<TaDaInformation />}>
                <Route path="tada-details" element={<TaDaDetails />} />
                <Route path="tada-summary" element={<TaDaSummary />} />
              </Route>
            </Route>
          </Route>

          {/* can view Factory */}
          <Route
            element={
              <RequireAuth
                allowedRoles={['Super Admin', ...Admins, 'Factory']}
              />
            }>
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }>
              <Route path="factory" element={<FactoryStock />} />
            </Route>
          </Route>

          <Route path="/welcome" element={<Welcome />} />
          <Route path="/form" element={<OutletForm />} />
        </Route>

        <Route path="test-theme" element={<TestTheme />} />

        {/* catch all */}
        <Route path="/unauthorized" element={<UnAuthorized />} />
        <Route path="*" element={<Missing />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default Router;

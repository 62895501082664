import React from 'react';
import AddCardIcon from '@mui/icons-material/AddCard';
import PaidIcon from '@mui/icons-material/Paid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { stringify } from 'qs';
import { Link, useSearchParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import usePayroll from '../../hooks/usePayroll';
import useShare from '../../hooks/useShare';
import ConfirmPaymentModal from './ConfirmPaymentModal';

function PayrollPayslip() {
  const [query] = useSearchParams();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();
  const { setHeader } = useHeader();

  const { sharePdf, isLoadingPdf, getFile, navigatorShare } = useShare();
  const viewRef = React.useRef(null);

  React.useEffect(() => {
    setHeader({ title: 'Payroll Payslip' });
  }, []);

  // filter states
  const [employeeList, setEmployeeList] = React.useState([]);
  const [employee, setEmployee] = React.useState({});
  const [month, setMonth] = React.useState(dayjs().subtract(1, 'month'));

  const [trigger, setTrigger] = React.useState(Date.now());
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);

  // get all employees
  React.useEffect(() => {
    axiosPrivate
      .get('/api/v1/employee?limit=none&sort=code')
      .then((res) => setEmployeeList(res?.data?.data))
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, []);

  // get data from query params
  React.useEffect(() => {
    const e = query.get('employee');
    const m = query.get('month');
    if (e) {
      setEmployee(employeeList.find((emp) => emp?.id === e));
    }
    if (m) {
      setMonth(dayjs(m, 'MMM_YYYY'));
    }
  }, [employeeList, query]);

  // set ticket link params
  const ticketParams = React.useMemo(() => {
    if (employee?.id && month.isValid()) {
      return stringify({
        employee: employee?.id,
        dateOfEntry: month?.format('YYYY-MM-DD'),
        redirect: `/payroll-payslip?employee=${
          employee?.id
        }&month=${month.format('MMM_YYYY')}`,
      });
    } else {
      return null;
    }
  }, [employee?.id, month]);

  // get data
  const { data, isLoading, isError, error, getData } = usePayroll();
  React.useEffect(() => {
    if (employee?.id && month.isValid() && trigger) {
      getData(month.startOf('month'), month.endOf('month'), employee?.id);
    }
  }, [employee?.id, month, trigger, getData]);

  if (isError) {
    expired(error);
  }

  // actual Data
  const d = React.useMemo(() => data?.[0], [data]);

  const PayslipView = React.useMemo(
    () => (
      <Container
        disableGutters
        maxWidth="sm"
        sx={{ p: 2, bgcolor: '#fff', borderRadius: 2, overflow: 'auto' }}>
        <Box ref={viewRef} id="payslip-view">
          <Box>
            <Typography align="center" variant="h6">
              Payslip - {dayjs(d?.startDate)?.format('MMMM YYYY')}
            </Typography>
            <Stack
              direction="row"
              sx={{
                mb: 1,
                gap: 2,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Typography variant="subtitle2">
                {d?.employee?.nameCode} - {d?.employee?.role?.employeeRole}
              </Typography>
              <Typography variant="subtitle2">
                {d?.employee?.role?.territoryType?.territoryType}:{' '}
                {
                  d?.employee?.territories?.[
                    `rank-${d?.employee?.role?.territoryType?.rank}`
                  ]?.name
                }
              </Typography>
            </Stack>

            <Table sx={tableStyle}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Work Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Attendance</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalAttendance}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Work With</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalWorkWith}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Collection</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalCollection}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Lifting</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalLifting}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>IMS Quantity</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalInMarketSalesQuantity}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>IMS Value</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalInMarketSalesValue}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Table sx={tableStyle}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Salary Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Basic Salary</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.salaryBasic}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total DA</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalDearnessAllowance}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total TA</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalTravelAllowance}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hotel Bill</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalHotelBill}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Other Bill</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalOtherBill}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Mobile Bill</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.salaryMobile}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Internet Bill</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.salaryInternet}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Table sx={tableStyle}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>Tickets</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Remarks</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {d?.ticketsPlus?.map((t) => (
                  <TableRow key={t?.id}>
                    <TableCell align="left">
                      {dayjs(t?.dateOfEntry).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ textTransform: 'capitalize' }}>
                      {t?.type}
                    </TableCell>
                    <TableCell align="center">{t?.remarks}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      {t?.approvedAmount}
                    </TableCell>
                  </TableRow>
                ))}
                {d?.ticketsMinus?.map((t) => (
                  <TableRow key={t?.id}>
                    <TableCell align="left">
                      {dayjs(t?.dateOfEntry).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ textTransform: 'capitalize' }}>
                      {t?.type}
                    </TableCell>
                    <TableCell align="center">{t?.remarks}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      {t?.approvedAmount}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align="right"
                    sx={{ fontWeight: 'bold' }}>
                    Total +
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalTicketsPlus}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align="right"
                    sx={{ fontWeight: 'bold' }}>
                    Total -
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalTicketsMinus}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Table sx={tableStyle}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Payment Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Previous Dues</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.previousDues}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Net Payable</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalPayable}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Net Paid</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalPaid}
                  </TableCell>
                </TableRow>
                {d?.totalPaidRemarks ? (
                  <TableRow>
                    <TableCell>Payment Remarks</TableCell>
                    <TableCell sx={{ whiteSpace: 'pre-line' }}>
                      {d?.totalPaidRemarks}
                    </TableCell>
                  </TableRow>
                ) : null}
                <TableRow>
                  <TableCell>Payable Due</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {d?.totalDue}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Table sx={tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Stats</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Basic ÷ Coll.</TableCell>
              <TableCell align="right">{d?.salaryCollectionPercent}%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Basic ÷ IMS</TableCell>
              <TableCell align="right">
                {d?.salaryInMarketSalesPercent}%
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TA ÷ Coll.</TableCell>
              <TableCell align="right">
                {d?.travelAllowanceCollectionPercent}%
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TA ÷ IMS</TableCell>
              <TableCell align="right">
                {d?.travelAllowanceInMarketSalesPercent}%
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Net Pay ÷ Coll.</TableCell>
              <TableCell align="right">
                {d?.totalPayCollectionPercent}%
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Net Pay ÷ IMS</TableCell>
              <TableCell align="right">
                {d?.totalPayInMarketSalesPercent}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Container>
    ),
    [d],
  );

  return (
    <Container disableGutters maxWidth="lg">
      <Box sx={{ p: 2, mb: 2, bgcolor: '#fff', borderRadius: 2 }}>
        <Stack
          direction={{ sm: 'row' }}
          sx={{ gap: 2, alignItems: 'center', justifyContent: 'center' }}>
          <Autocomplete
            disabled={employeeList?.length < 1}
            fullWidth
            size="small"
            options={employeeList}
            value={employee}
            getOptionLabel={(option) => option?.nameCode || ''}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => setEmployee(value)}
            renderInput={(params) => (
              <TextField {...params} label="Select Employee" />
            )}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Month"
              inputFormat="MMM YYYY"
              views={['month', 'year']}
              value={month}
              onChange={(newValue) => setMonth(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" />
              )}
            />
          </LocalizationProvider>
        </Stack>

        <Stack
          direction={{ sm: 'row' }}
          sx={{
            mt: 2,
            gap: 2,
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'space-between' },
          }}>
          <Stack
            direction="row"
            sx={{ gap: 2, alignItems: 'center', justifyContent: 'center' }}>
            <ReactToPrint
              trigger={() => (
                <Button
                  disabled={!d?.employee?.id}
                  color="primary"
                  variant="outlined"
                  startIcon={<PrintIcon />}>
                  Print
                </Button>
              )}
              content={() => viewRef.current}
            />

            {!getFile ? (
              <Button
                disabled={!d?.employee?.id || isLoadingPdf}
                color="primary"
                variant="outlined"
                startIcon={<PictureAsPdfIcon />}
                onClick={() =>
                  sharePdf(
                    'payslip-view',
                    `Payslip ${month?.format('MMM YYYY')} - ${
                      employee?.name
                    } (${employee.code})`,
                    675,
                  )
                }>
                {isLoadingPdf ? 'Preparing...' : 'Prepare PDF'}
              </Button>
            ) : (
              <Button
                disabled={!d?.employee?.id}
                color="success"
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={() => navigatorShare(getFile)}>
                Share PDF
              </Button>
            )}
          </Stack>

          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ gap: 2, alignItems: 'center', justifyContent: 'center' }}>
            <Button
              sx={{ flex: 'auto' }}
              disabled={!ticketParams}
              component={Link}
              to={`/payroll-ticket/add?${ticketParams}`}
              color="secondary"
              variant="contained"
              startIcon={<AddCardIcon />}>
              Add Ticket
            </Button>

            <Button
              sx={{ flex: 'auto' }}
              disabled={!d?.employee?.id}
              color="success"
              variant="contained"
              startIcon={<PaidIcon />}
              onClick={() => {
                setShowPaymentModal(true);
              }}>
              Confirm Payment
            </Button>
          </Stack>
        </Stack>
      </Box>

      {isLoading || employeeList?.length < 1 ? (
        <Stack
          sx={{
            mt: 4,
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <CircularProgress disableShrink />
          <Typography color="text.disabled" variant="subtitle1">
            Loading
          </Typography>
        </Stack>
      ) : (
        <>
          {!d?.employee?.id ? (
            !employee?.id ? (
              <Typography
                color="text.disabled"
                align="center"
                sx={{ mt: 4, fontSize: '18px', fontWeight: 'bold' }}>
                Select an employee and month
              </Typography>
            ) : (
              <Typography
                color="error.main"
                align="center"
                sx={{ mt: 4, fontSize: '18px', fontWeight: 'bold' }}>
                {employee?.name} was absent on {month?.format('MMM YYYY')}
              </Typography>
            )
          ) : (
            PayslipView
          )}
        </>
      )}

      <ConfirmPaymentModal
        info={d}
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        refetch={() => setTrigger(Date.now())}
      />
    </Container>
  );
}

export default PayrollPayslip;

const tableStyle = {
  marginBottom: 3,
  overflow: 'auto',
  th: {
    padding: 0.5,
    borderBottom: '2px solid black',
    fontSize: 18,
    fontWeight: 'bold',
    // textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  td: {
    padding: 0.5,
    borderBottom: '1px solid black',
    fontSize: 14,
  },
};

import axios from 'axios';

// const BASE_URL = 'http://localhost:5000';
// const BASE_URL = 'http://192.168.3.183:3000'; // LOCAL server
// const BASE_URL = 'https://awsmdb7.smartmgts.com:8443'; // LIVE server
const BASE_URL = 'https://smart-sales-test.onrender.com'; // TEST server

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Cross-Origin-Resource-Policy'] = 'cross-origin';

export default axios.create();

export const axiosPrivate = axios.create({
  withCredentials: true,
});

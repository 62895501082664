import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import { DateRangeColumnFilter } from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import roundToDigit from '../../utils/roundToDigit';

function LiftingPlanDetails() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();
  const { role, user, userId, Admins } = useAuth();

  React.useEffect(() => {
    setHeader({ title: 'Lifting Plan' });
  }, []);

  const [deleteShow, setDeleteShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');

  const handleAdd = () => {
    navigate('/lifting-plan-details/add');
  };
  const handleEdit = (id) => {
    navigate(`/lifting-plan-details/edit/${id}`);
  };
  const handleDeleteSelection = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };
  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/lifting-commitment/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteId('');
        setDeleteShow(false);
      });
  };

  const { data, isLoading, error, isError, refetch } = useQuery(
    ['lifting-commitment'],
    () => {
      let url = '/api/v1/lifting-commitment/details?populate=yes';

      if (!['Super Admin', ...Admins, 'IMS'].includes(role)) {
        url += `&parentTerritory=${user?.employee?.territory?.id}`;
      }

      return axiosPrivate.get(url).then((res) =>
        res?.data?.data?.map((i) => ({
          ...i,
          achievement: roundToDigit(
            (i?.totalCollection * 100) / i?.commitmentAmount,
          ),
        })),
      );
    },
  );

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: 'Entered By',
        accessor: 'employee.nameCode',
      },
      {
        Header: 'Entry Time',
        accessor: 'createdAt',
        Cell: ({ value }) =>
          value ? dayjs(value).format('DD/MM/YYYY hh:mm A') : null,
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Planned From',
        accessor: 'timeStart',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : null),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Planned To',
        accessor: 'timeEnd',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : null),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Region',
        accessor: 'customer.territories.rank-2.name',
      },
      {
        Header: 'HQ',
        accessor: 'customer.territories.rank-3.name',
      },
      {
        Header: 'Point/Town',
        accessor: 'customer.territories.rank-4.name',
      },
      {
        Header: 'Customer',
        accessor: 'customer.nameCode',
      },
      {
        Header: 'Planned Amount',
        accessor: 'commitmentAmount',
        aggregate: 'sum',
      },
      {
        Header: 'Total Collection',
        accessor: 'totalCollection',
        aggregate: 'sum',
      },
      {
        Header: 'Completed',
        accessor: 'achievement',
        Cell: ({ value }) => `${value}%`,
      },
      {
        Header: 'Action',
        id: 'Action',
        hidePrint: true,
        Cell: ({ row }) => {
          if (
            (role !== 'Admin' && ['Super Admin', ...Admins].includes(role)) ||
            row?.original?.recordedBy?.id === userId
          ) {
            return (
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={() => handleEdit(row?.original?.id)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDeleteSelection(row?.original?.id)}
                  className="Table_icon_btn delete">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          }

          return null;
        },
      },
    ],
    [userId],
  );

  if (isError) {
    expired(error);
  }

  if (pathname !== '/lifting-plan-details') {
    return <Outlet context={[refetch]} />;
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        noWrap
        onAddButtonClick={handleAdd}
        addButtonTooltip="Add Lifting Plan"
        filename="Lifting_Plan_Details"
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={handleDelete}
        onHide={() => {
          setDeleteId('');
          setDeleteShow(false);
        }}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default LiftingPlanDetails;

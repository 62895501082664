import React from 'react';
import DashTitle from './DashTitle';
import { Stack } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import Card from './Card';

export default function ChartsCard() {
  return (
    <>
      <DashTitle title={'Graphs & Charts'} />
      <Stack
        direction="row"
        justifyContent="center"
        mt={3}
        sx={{ flexWrap: 'wrap', gap: '20px' }}>
        {/* <Card /> */}
        {CardInfo.map((info) => (
          <Card key={info.url} info={info} />
        ))}
      </Stack>
    </>
  );
}

const iconStyle = {
  fontSize: '50px',
};

const CardInfo = [
  {
    title: 'SKU Analysis',
    url: 'charts/sku-anylysis',
    icon: <BarChartIcon sx={iconStyle} />,
  },
];

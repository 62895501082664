import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import React from 'react';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { CircularProgress, ListItemIcon, Typography } from '@mui/material';

function ModifyTransactionType() {
  const [refetch] = useOutletContext();
  // form data states
  const [transactionType, setTransactionType] = React.useState('');
  const [senderType, setSenderType] = React.useState('');
  const [receiverType, setReceiverType] = React.useState('');
  const [functionX, setFunction] = React.useState('');
  const [productCondition, setProductCondition] = React.useState('');
  const [priceType, setPriceType] = React.useState('');
  const [senderLedgerAction, setSenderLedgerAction] = React.useState('');
  const [receiverLedgerAction, setReceiverLedgerAction] = React.useState('');
  const [autoUpdatePayment, setAutoUpdatePayment] = React.useState(false);
  const [autoUpdateDelivery, setAutoUpdateDelivery] = React.useState(false);
  const [applyOffer, setApplyOffer] = React.useState(false);
  const [discountPercent, setDiscountPercent] = React.useState(0);
  const [description, setDescription] = React.useState('');
  const [isActive, setIsActive] = React.useState(true);

  const [customerTypes, setCustomerTypes] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDamagedDisabled, setIsDamagedDisabled] = React.useState(false);

  const { transactionTypeId } = useParams();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const handleSubmit = () => {
    axiosPrivate({
      method: transactionTypeId ? 'patch' : 'post',
      url: transactionTypeId
        ? `/api/v1/transaction/type/${transactionTypeId}`
        : '/api/v1/transaction/type',
      data: {
        transactionType,
        senderType,
        receiverType,
        function: functionX,
        productCondition,
        priceType,
        senderLedgerAction,
        receiverLedgerAction,
        autoUpdatePayment,
        autoUpdateDelivery,
        applyOffer,
        discountPercent,
        description,
        isActive,
      },
    })
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
        navigate('/settings/transaction-type');
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  };

  React.useEffect(() => {
    if (transactionTypeId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/transaction/type/${transactionTypeId}`)
        .then((res) => {
          setTransactionType(res?.data?.data?.transactionType);
          setSenderType(res?.data?.data?.senderType);
          setReceiverType(res?.data?.data?.receiverType);
          setFunction(res?.data?.data?.function);
          setProductCondition(res?.data?.data?.productCondition);
          setPriceType(res?.data?.data?.priceType);
          setSenderLedgerAction(res?.data?.data?.senderLedgerAction);
          setReceiverLedgerAction(res?.data?.data?.receiverLedgerAction);
          setAutoUpdatePayment(res?.data?.data?.autoUpdatePayment);
          setAutoUpdateDelivery(res?.data?.data?.autoUpdateDelivery);
          setApplyOffer(res?.data?.data?.applyOffer);
          setDiscountPercent(res?.data?.data?.discountPercent);
          setDescription(res?.data?.data?.description);
          setIsActive(res?.data?.data?.isActive);
        })
        .catch((err) => {
          toast.warn(err?.response?.data?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [transactionTypeId]);

  React.useEffect(() => {
    if (functionX === 'sale') {
      setProductCondition('intact');
      setIsDamagedDisabled(true);
    } else {
      setIsDamagedDisabled(false);
    }
  }, [functionX]);

  React.useEffect(() => {
    if (!autoUpdatePayment) {
      setAutoUpdateDelivery(false);
    }
  }, [autoUpdatePayment]);

  React.useEffect(() => {
    axiosPrivate
      .get('/api/v1/customer/type?populate=yes&sort=rank&isActive=true')
      .then((res) => {
        setCustomerTypes(res?.data?.data);
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
      });
  }, []);

  const customerTypesMenu = React.useMemo(() => {
    return customerTypes.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.customerType}
        </MenuItem>
      );
    });
  }, [customerTypes.length]);

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height={1}>
        <CircularProgress />
        <Typography color="text.disabled">Loading ...</Typography>
      </Stack>
    );
  }

  return (
    <>
      <IconButton
        component={Link}
        to="/settings/transaction-type"
        state={{ title: 'Transactions Type' }}
        aria-label="go back"
        sx={{ mb: 1 }}>
        <ArrowBackIcon />
      </IconButton>

      <Container maxWidth="md">
        <Paper elevation={2} sx={{ p: 3 }}>
          <Stack gap={3}>
            <TextField
              label="Transaction type"
              variant="outlined"
              fullWidth
              value={transactionType}
              onChange={(e) => setTransactionType(e.target.value)}
            />

            <FormControl>
              <FormLabel id="transaction-type-function-label">
                Function
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="transaction-type-function-label"
                value={functionX}
                onChange={(e) => setFunction(e.target.value)}>
                <FormControlLabel
                  value="sale"
                  control={<Radio />}
                  label="Sale"
                />
                <FormControlLabel
                  value="transfer"
                  control={<Radio />}
                  label="Transfer"
                />
              </RadioGroup>
            </FormControl>

            <FormControl>
              <FormLabel id="transaction-type-product-condition-label">
                Product condition
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="transaction-type-product-condition-label"
                value={productCondition}
                onChange={(e) => setProductCondition(e.target.value)}>
                <FormControlLabel
                  value="intact"
                  control={<Radio />}
                  label="Intact"
                />
                <FormControlLabel
                  value="damaged"
                  disabled={isDamagedDisabled}
                  control={<Radio />}
                  label="Damaged"
                />
                <FormControlLabel
                  value="both"
                  disabled={isDamagedDisabled}
                  control={<Radio />}
                  label="Both"
                />
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="transaction-type-sender-type-label">
                Sender type
              </InputLabel>
              <Select
                labelId="transaction-type-sender-type-label"
                id="transaction-type-sender-type"
                label="Sender type"
                value={senderType}
                onChange={(e) => setSenderType(e.target.value)}>
                {customerTypesMenu}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel id="transaction-type-sender-ledger-action-label">
                Sender ledger action
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="transaction-type-sender-ledger-action-label"
                value={senderLedgerAction}
                onChange={(e) => setSenderLedgerAction(e.target.value)}>
                <FormControlLabel
                  value="debit"
                  control={<Radio />}
                  label="Debit (-)"
                />
                <FormControlLabel
                  value="credit"
                  control={<Radio />}
                  label="Credit (+)"
                />
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="None"
                />
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="transaction-type-receiver-type-label">
                Receiver type
              </InputLabel>
              <Select
                labelId="transaction-type-receiver-type-label"
                id="transaction-type-receiver-type"
                label="Receiver type"
                value={receiverType}
                onChange={(e) => setReceiverType(e.target.value)}>
                {customerTypesMenu}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel id="transaction-type-receiver-ledger-action-label">
                Receiver ledger action
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="transaction-type-receiver-ledger-action-label"
                value={receiverLedgerAction}
                onChange={(e) => setReceiverLedgerAction(e.target.value)}>
                <FormControlLabel
                  value="debit"
                  control={<Radio />}
                  label="Debit (-)"
                />
                <FormControlLabel
                  value="credit"
                  control={<Radio />}
                  label="Credit (+)"
                />
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="None"
                />
              </RadioGroup>
            </FormControl>

            <FormControl>
              <FormLabel id="transaction-type-price-type-label">
                Price type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="transaction-type-price-type-label"
                value={priceType}
                onChange={(e) => setPriceType(e.target.value)}>
                <FormControlLabel
                  value="dsrPrice"
                  control={<Radio />}
                  label="DSR price"
                />
                <FormControlLabel
                  value="dealerPrice"
                  control={<Radio />}
                  label="Dealer price"
                />
                <FormControlLabel
                  value="tradePrice"
                  control={<Radio />}
                  label="Trade price"
                />
                <FormControlLabel
                  value="maxRetailPrice"
                  control={<Radio />}
                  label="Max retail price"
                />
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="None"
                />
              </RadioGroup>
            </FormControl>

            <TextField
              label="Discount(%)"
              variant="outlined"
              fullWidth
              type="number"
              value={discountPercent}
              onChange={(e) => {
                const d = parseFloat(e.target.value);
                if (d >= 0 && d <= 100) {
                  setDiscountPercent(d);
                } else {
                  setDiscountPercent(0);
                }
              }}
            />

            <ListItem disablePadding>
              <ListItemIcon>
                <Checkbox
                  onChange={() => setApplyOffer(!applyOffer)}
                  checked={applyOffer}
                  inputProps={{
                    'aria-labelledby': 'transaction-type-apply-offer',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id="transaction-type-apply-offer"
                primary="Apply trade offer"
                secondary="Auto calculate free items quantity when ordering."
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemIcon>
                <Checkbox
                  onChange={() => setAutoUpdatePayment(!autoUpdatePayment)}
                  checked={autoUpdatePayment}
                  inputProps={{
                    'aria-labelledby': 'transaction-type-auto-payment',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id="transaction-type-auto-payment"
                primary="Auto confirm payment"
                secondary="Update the legder according to above settings autometically after confirming products."
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemIcon>
                <Checkbox
                  disabled={!autoUpdatePayment}
                  onChange={() => setAutoUpdateDelivery(!autoUpdateDelivery)}
                  checked={autoUpdateDelivery}
                  inputProps={{
                    'aria-labelledby': 'transaction-type-auto-delivery',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id="transaction-type-auto-delivery"
                primary="Auto confirm delivery"
                secondary="Update the stock autometically after confirming products."
              />
            </ListItem>

            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <FormControlLabel
              label={isActive ? 'Active' : 'Inactive'}
              value="active"
              control={
                <Switch
                  color="primary"
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                />
              }
            />
          </Stack>
        </Paper>

        <Box sx={{ pt: 3, textAlign: 'center' }}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit}>
            Save transaction type
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default ModifyTransactionType;

import React, { useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import { BankList, MobileBankList } from '../../staticData/Banks';
import roundToDigit from '../../utils/roundToDigit';

function AddPayments() {
  const navigate = useNavigate();
  const [refetch] = useOutletContext();
  const { userId, role, Admins } = useAuth();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();

  const isAllowed = ['Super Admin', ...Admins, 'Accounts', 'DO'].includes(role);

  const [ledgerEntries, setLedgerEntries] = useState([
    {
      key: 101,
      date: dayjs(),
      type: '',
      amount: '',
      remarks: '',
    },
  ]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [trigger, setTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [customersList, setCustomersList] = useState([]);

  const [customer, setCustomer] = useState({});
  const [paymentMode, setPaymentMode] = useState('Bank');
  const [bankName, setBankName] = useState('');
  const [bankBranch, setBankBranch] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [transactionID, setTransactionID] = useState('');
  const [reference, setReference] = useState('');

  // get customer data
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/customer?populate=yes&limit=none')
      .then((res) => {
        setCustomersList(res?.data?.data);
      })
      .catch((error) => expired(error));
  }, []);

  useEffect(() => {
    let sum = 0;
    ledgerEntries.forEach((i) => {
      const amt = parseFloat(i?.amount);
      if (!Number.isNaN(amt)) {
        sum += amt;
      }
    });
    setTotalAmount(roundToDigit(sum));
  }, [trigger]);

  const handleAddLedgerEntry = () => {
    const entries = [
      ...ledgerEntries,
      {
        key: Date.now(),
        date: dayjs(),
        type: '',
        amount: '',
        remarks: '',
      },
    ];

    setLedgerEntries(entries);
    setTrigger(Date.now());
  };

  const handleRemoveLedgerEntry = (key) => {
    const entries = [...ledgerEntries];
    const index = entries.findIndex((i) => i.key === key);
    entries.splice(index, 1);

    setLedgerEntries(entries);
    setTrigger(Date.now());
  };

  const handleEditLedgerEntry = (key, value, field) => {
    const entries = [...ledgerEntries];
    const entry = entries.find((i) => i.key === key);
    entry[field] = value;

    setLedgerEntries(entries);
    setTrigger(Date.now());
  };

  const handleSubmit = () => {
    const data = [];
    let isMissingData = false;

    if (!customer?.id) {
      isMissingData = true;
    }

    ledgerEntries.forEach((i) => {
      if (
        i?.type &&
        i?.amount &&
        i?.date?.isValid() &&
        !i?.date?.isAfter(dayjs().add(1, 'day'), 'date')
      ) {
        const xAmount = parseFloat(i?.amount) * -1;
        const entryDate = i?.date?.toDate();

        data.push({
          type: i?.type,
          customer: customer?.id,
          recordedBy: userId,
          entryAmount: xAmount,
          approvedAmount: xAmount,
          totalAmount: xAmount,
          dateOfEntry: entryDate,
          dateOfDeposit: entryDate,
          dateOfClearance: entryDate,
          paymentMode: paymentMode,
          bankName: bankName,
          bankBranch: bankBranch,
          accountNumber: accountNumber,
          transactionID: transactionID,
          reference: reference,
          remarks: i?.remarks,
        });
      } else {
        isMissingData = true;
      }
    });

    if (isMissingData) {
      toast.warn('Important data missing! Please check all payment info.', {});
    } else {
      setIsLoading(true);

      axiosPrivate({
        method: 'post',
        url: '/api/v1/ledger/insert',
        data,
      })
        .then((res) => {
          toast.success(res?.data?.message);
          refetch();
          navigate('/payments');
        })
        .catch((error) => {
          expired(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const ledgerEntryTypes = [
    { value: '', label: 'None' },
    { value: 'salary', label: 'Salary' },
    { value: 'transport', label: 'Transport' },
    { value: 'damage', label: 'Damage' },
    { value: 'return', label: 'Return' },
    { value: 'bonus', label: 'Bonus' },
    { value: 'commission', label: 'Commission' },
    { value: 'other', label: 'Other funds' },
  ];

  const LedgerEntriesControl = useMemo(
    () =>
      ledgerEntries?.map((i, index) => (
        <Stack
          key={i?.key}
          component={Paper}
          variant="outlined"
          direction="row"
          sx={{ width: '100%', p: 1, gap: 1, alignItems: 'flex-start' }}>
          <Box sx={{ width: '32px', py: 2 }}>
            <Typography align="right" color="primary" variant="h6">
              {index + 1}.
            </Typography>
          </Box>

          <Stack sx={{ width: '100%', py: 2, gap: 3 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                fullWidth
                label="Date"
                inputFormat="M/D/YYYY"
                maxDate={dayjs().add(1, 'day')}
                value={i?.date}
                onChange={(newValue) =>
                  handleEditLedgerEntry(i?.key, newValue, 'date')
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>

            <TextField
              fullWidth
              select
              label="Type"
              value={i?.type}
              onChange={(e) =>
                handleEditLedgerEntry(i?.key, e.target.value, 'type')
              }>
              {ledgerEntryTypes.map((i) => (
                <MenuItem key={i.label} disabled={!i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              type="number"
              label="Amount"
              value={i?.amount}
              onChange={(e) =>
                handleEditLedgerEntry(i?.key, e.target.value, 'amount')
              }
              onWheel={(e) => e.target.blur()}
            />

            <TextField
              fullWidth
              type="text"
              multiline
              rows={2}
              label="Remarks"
              value={i?.remarks}
              onChange={(e) =>
                handleEditLedgerEntry(i?.key, e.target.value, 'remarks')
              }
            />
          </Stack>

          <Box sx={{ py: 2 }}>
            <Tooltip title="Remove" placement="right">
              <IconButton
                disabled={ledgerEntries?.length <= 1}
                aria-label="Remove"
                size="small"
                color="error"
                onClick={() => handleRemoveLedgerEntry(i.key)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      )),
    [trigger],
  );

  return (
    <Container maxWidth="sm">
      <IconButton
        aria-label="Go back"
        sx={{ mb: 1 }}
        onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>

      <Stack component={Paper} sx={{ p: 3, gap: 3, alignItems: 'center' }}>
        <Autocomplete
          disabled={customersList.length < 1}
          fullWidth
          options={customersList}
          value={customer}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => setCustomer(value)}
          getOptionLabel={(option) =>
            option?.nameCode
              ? `${option?.nameCode}${option?.isActive ? '' : ' ❌'}`
              : ''
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Customer" />
          )}
        />
      </Stack>

      <Stack
        component={Paper}
        sx={{ p: 3, my: 3, gap: 3, alignItems: 'center' }}>
        <Typography color="text.secondary" variant="subtitle2">
          Payment info
        </Typography>

        <Typography
          color="text.disabled"
          variant="caption"
          align="right"
          sx={{ width: '100%' }}>
          *Date format: M/D/YYYY
        </Typography>

        {LedgerEntriesControl}

        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ maxWidth: 96 }}
          onClick={handleAddLedgerEntry}>
          Add
        </Button>

        <Typography sx={{ width: '100%', fontSize: '1.125rem' }}>
          Total amount: <strong>{totalAmount}</strong>
        </Typography>
      </Stack>

      <Stack component={Paper} sx={{ p: 3, gap: 3, alignItems: 'center' }}>
        <Typography color="text.secondary" variant="subtitle2">
          Account info
        </Typography>

        <TextField
          fullWidth
          select
          label="Payment Mode"
          value={paymentMode}
          onChange={(e) => setPaymentMode(e.target.value)}>
          <MenuItem value="Bank">Bank</MenuItem>
          <MenuItem value="Mobile Bank">Mobile Bank</MenuItem>
        </TextField>

        {paymentMode === 'Bank' ? (
          <>
            <Autocomplete
              fullWidth
              options={BankList}
              value={bankName}
              onChange={(event, value) => setBankName(value)}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Bank Name" />
              )}
            />

            <TextField
              fullWidth
              type="text"
              label="Account Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />

            <TextField
              fullWidth
              type="text"
              label="Bank Branch"
              value={bankBranch}
              onChange={(e) => setBankBranch(e.target.value)}
            />
          </>
        ) : (
          <>
            <Autocomplete
              fullWidth
              options={MobileBankList}
              value={bankName}
              onChange={(event, value) => setBankName(value)}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Mobile Bank Name" />
              )}
            />

            <TextField
              fullWidth
              type="text"
              label="Mobile Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </>
        )}

        <TextField
          fullWidth
          type="text"
          label="Transaction Code"
          value={transactionID}
          onChange={(e) => setTransactionID(e.target.value)}
        />

        <TextField
          fullWidth
          type="text"
          label="Reference"
          value={reference}
          onChange={(e) => setReference(e.target.value)}
        />

        <Button
          disabled={isLoading || !isAllowed}
          fullWidth
          color="primary"
          variant="contained"
          size="large"
          onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </Container>
  );
}

export default AddPayments;

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useHeader from '../../hooks/useHeader';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import {
  Autocomplete,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from '../../api/axios';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

function ModifySReportDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [refetch] = useOutletContext();
  const axiosPrivate = useAxiosPrivate();
  const { setHeader } = useHeader();
  const { user, userId } = useAuth();

  const editId = params?.reportId;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDateError, setIsDateError] = React.useState(false);
  const [hasReportError, setHasReportError] = React.useState(false);
  const [hasCountError, setHasCountError] = React.useState(false);

  // selection states
  const [employeeList, setEmployeeList] = React.useState([]);
  const [routeList, setRouteList] = React.useState([]);

  const [employee, setEmployee] = React.useState({});
  const [reportDate, setReportDate] = React.useState(new Date());
  const [route, setRoute] = React.useState('');
  const [outletCount, setOutletCount] = React.useState(0);
  const [orderQuantity, setOrderQuantity] = React.useState(0);
  const [memoCount, setMemoCount] = React.useState(0);
  const [deliveryQuantity, setDeliveryQuantity] = React.useState(0);
  const [liftingQuantity, setLiftingQuantity] = React.useState(0);
  const [stockQuantity, setStockQuantity] = React.useState(0);
  const [orderValue, setOrderValue] = React.useState(0);
  const [deliveryValue, setDeliveryValue] = React.useState(0);

  React.useEffect(() => {
    if (editId) {
      setHeader({ title: 'Edit SR Report' });
    } else {
      setHeader({ title: 'Add SR Report' });
    }
  }, [editId]);

  // get routes list
  React.useEffect(() => {
    axios
      .get(
        `/api/v1/territory?limit=none&isActive=true&parentTerritory=${
          editId ? employee?.territory?.id : employee?.territory
        }`,
      )
      .then((res) => {
        setRouteList(res?.data?.data);
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, [editId, employee]);

  // get editing entry
  React.useEffect(() => {
    if (editId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/sales-activity/${editId}?populate=yes`)
        .then((res) => {
          setEmployee(res?.data?.data?.employee);
          setReportDate(res?.data?.data?.reportDate);
          setRoute(res?.data?.data?.route);
          setOutletCount(res?.data?.data?.outletCount);
          setOrderQuantity(res?.data?.data?.orderQuantity);
          setMemoCount(res?.data?.data?.memoCount);
          setDeliveryQuantity(res?.data?.data?.deliveryQuantity);
          setLiftingQuantity(res?.data?.data?.liftingQuantity);
          setStockQuantity(res?.data?.data?.stockQuantity);
          setOrderValue(res?.data?.data?.orderValue);
          setDeliveryValue(res?.data?.data?.deliveryValue);
        })
        .catch((error) => toast.warn(error?.response?.data?.message))
        .finally(() => setIsLoading(false));
    }
  }, [editId]);

  // get employee list
  React.useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/employee?limit=none&isActive=true&sort=code&parentTerritory=${user?.employee?.territory?.id}`,
      )
      .then((res) => {
        setEmployeeList(res?.data?.data);
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, [user?.employee?.territory?.id]);

  // get current date and employee entries
  const { data: hasSrReportData } = useQuery(
    ['single-sales-activity', reportDate, employee],
    () => {
      let url = '/api/v1/sales-activity?limit=none&sort=-reportDate';

      url += `&reportDate[gte]=${dayjs(reportDate)
        .startOf('date')
        .toISOString()}`;

      url += `&reportDate[lte]=${dayjs(reportDate)
        .endOf('date')
        .toISOString()}`;

      url += `&employee=${employee.id}`;

      return axiosPrivate
        .get(url)
        .then((res) => Boolean(res?.data?.data.length > 0));
    },
  );

  React.useEffect(() => {
    if (!editId && employee.id && hasSrReportData) {
      toast.warn('Data already exits');
      setHasReportError(true);
    } else {
      setHasReportError(false);
    }
  }, [editId, employee.id, hasSrReportData]);

  React.useEffect(() => {
    if (
      !(
        outletCount ||
        orderQuantity ||
        memoCount ||
        deliveryQuantity ||
        liftingQuantity ||
        stockQuantity ||
        orderValue ||
        deliveryValue
      )
    ) {
      setHasCountError(true);
    } else {
      setHasCountError(false);
    }
  }, [
    outletCount,
    orderQuantity,
    memoCount,
    deliveryQuantity,
    liftingQuantity,
    stockQuantity,
    orderValue,
    deliveryValue,
  ]);

  const handleSubmit = () => {
    setIsLoading(true);

    let method = 'post';
    let url = '/api/v1/sales-activity';
    const data = {
      recordedBy: userId,
      employee: employee?.id,
      reportDate,
      route,
      outletCount,
      orderQuantity,
      memoCount,
      deliveryQuantity,
      liftingQuantity,
      stockQuantity,
      orderValue,
      deliveryValue,
    };

    if (editId) {
      method = 'patch';
      url += `/${editId}`;
    }

    axiosPrivate({ method, url, data })
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
        navigate('/sr-report-entries');
      })
      .catch((error) => toast.warn(error?.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <Container maxWidth="sm">
      <IconButton
        component={Link}
        to="/sr-report-entries"
        aria-label="Go back"
        edge="start"
        sx={{ mb: 1 }}>
        <ArrowBackIcon />
      </IconButton>

      <Stack component={Paper} sx={{ px: 2, py: 3, gap: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Report Date"
            disabled={true}
            disableFuture
            fullWidth
            inputFormat="DD/MM/YYYY"
            views={['month', 'year', 'day']}
            value={reportDate}
            onError={(reason) => setIsDateError(Boolean(reason))}
            onChange={(newValue) => setReportDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>

        <Autocomplete
          disabled={editId || isLoading || employeeList?.length < 1}
          fullWidth
          autoComplete
          autoHighlight
          autoSelect
          options={employeeList}
          value={employee}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => {
            setEmployee(value);
            setRoute('');
          }}
          getOptionLabel={(option) => option?.nameCode || ''}
          renderInput={(params) => (
            <TextField {...params} label="Select SO/SR" />
          )}
        />

        <Autocomplete
          disabled={isLoading || !employee?.id}
          fullWidth
          autoComplete
          autoHighlight
          autoSelect
          freeSolo
          options={routeList.map((route) => route.name)}
          value={route}
          onChange={(event, value) => {
            setRoute(value);
          }}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Route/Beat" />
          )}
        />

        {/* <TextField
          disabled={isLoading}
          label="Route/Beat"
          type="text"
          value={route}
          onChange={(e) => setRoute(e.target.value)}
        /> */}

        <TextField
          disabled={isLoading}
          label="Outlets Count"
          fullWidth
          type="number"
          onWheel={(e) => e.target.blur()}
          value={outletCount}
          onChange={(e) => setOutletCount(parseFloat(e.target.value))}
        />

        <TextField
          disabled={isLoading}
          label="Order Quantity"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">cartons</InputAdornment>
            ),
          }}
          onWheel={(e) => e.target.blur()}
          value={orderQuantity}
          onChange={(e) => setOrderQuantity(parseFloat(e.target.value))}
        />

        <TextField
          disabled={isLoading}
          label="Order Value"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">Tk</InputAdornment>,
          }}
          onWheel={(e) => e.target.blur()}
          value={orderValue}
          onChange={(e) => setOrderValue(parseFloat(e.target.value))}
        />

        <TextField
          disabled={isLoading}
          label="Memos Count"
          fullWidth
          type="number"
          onWheel={(e) => e.target.blur()}
          value={memoCount}
          onChange={(e) => setMemoCount(parseFloat(e.target.value))}
        />

        <TextField
          disabled={isLoading}
          label="Delivery Quantity"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">cartons</InputAdornment>
            ),
          }}
          onWheel={(e) => e.target.blur()}
          value={deliveryQuantity}
          onChange={(e) => setDeliveryQuantity(parseFloat(e.target.value))}
        />

        <TextField
          disabled={isLoading}
          label="Delivery Value"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">Tk</InputAdornment>,
          }}
          onWheel={(e) => e.target.blur()}
          value={deliveryValue}
          onChange={(e) => setDeliveryValue(parseFloat(e.target.value))}
        />

        <TextField
          disabled={isLoading}
          label="Lifting Quantity"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">cartons</InputAdornment>
            ),
          }}
          onWheel={(e) => e.target.blur()}
          value={liftingQuantity}
          onChange={(e) => setLiftingQuantity(parseFloat(e.target.value))}
        />

        <TextField
          disabled={isLoading}
          label="Stock Quantity"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">cartons</InputAdornment>
            ),
          }}
          onWheel={(e) => e.target.blur()}
          value={stockQuantity}
          onChange={(e) => setStockQuantity(parseFloat(e.target.value))}
        />

        {hasReportError ? (
          <Typography align="center" color="error" variant="body2">
            Data already exits.
          </Typography>
        ) : hasCountError ? (
          <Typography align="center" color="error" variant="body2">
            Please enter data.
          </Typography>
        ) : null}

        <Button
          disabled={isLoading || hasReportError || hasCountError || isDateError}
          fullWidth
          variant="contained"
          size="large"
          onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </Container>
  );
}

export default ModifySReportDetails;
